export function useHrefLang(hreflang) {
    // TODO: add env var that holds frontend host
    const config = useRuntimeConfig();
    const url = `${config.public.domain}`;
    if (!hreflang) return;
    if (hreflang.de) {
        useHead({
            link: {
                hid: 'hreflang-de',
                rel: 'alternate',
                hreflang: 'de',
                href: `${url}${hreflang.de}`,
            },
        });
    }
    if (hreflang.fr) {
        useHead({
            link: {
                hid: 'hreflang-fr',
                rel: 'alternate',
                hreflang: 'fr',
                href: `${url}${hreflang.fr}`,
            },
        });
    }
}
